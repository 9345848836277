import { Box, Container, Flex, Text } from '@qga/roo-ui/components';
import React from 'react';
import styled from '@emotion/styled';
import useSticky from '@/hooks/useSticky';
import useSize from '@/shared/hooks/useSize';
import Portal from '@experiences-ui/shared/v2/components/Portal';
import TravellingTo from '../TravellingTo';
import PayWithButtonGroup from '@/components/PayWithButtonGroup';
import { themeGet } from 'styled-system';

const Content = styled(Flex)`
  gap: 1rem;
`;

// I apologise for this CSS, but it's all internal @qga/roo-ui styling
// and impossible to style nicely.
const PayWithContainer = styled(Box)`
  label {
    height: 50px;
    padding: 4px;

    span:first-of-type {
      font-size: 15px;
      line-height: 18px;
    }

    span:last-of-type {
      font-size: 14px;
      line-height: 20px;
    }
  }

  @media screen and (max-width: ${themeGet('breakpoints.0')}) {
    label {
      min-width: 75px;
    }
  }

  ${Text} {
    white-space: nowrap;
  }
`;

const FilterBar = ({ destination, destinations, setDestination }) => {
  return (
    <Box bg="white" borderBottom="1" borderColor="greys.smoke">
      <Container px="0">
        <Content
          py="4"
          px="3"
          justifyContent="space-between"
          alignItems={['flex-start', null, 'center']}
        >
          <TravellingTo
            value={destination?.name}
            destinations={destinations}
            onSubmit={setDestination}
          />
          <PayWithContainer pb={['1', null, '6']}>
            <PayWithButtonGroup />
          </PayWithContainer>
        </Content>
      </Container>
    </Box>
  );
};

const FilterBanner = ({ destination, destinations, setDestination }) => {
  const { sticky, ref: stickyRef } = useSticky();
  const { height, ref: sizeRef } = useSize();

  return (
    <Box ref={stickyRef}>
      {sticky ? (
        <>
          <Box data-testid="FILTER_BANNER_PLACEHOLDER" height={height} />
          <Portal selector="inPageNavigation-root">
            <Box
              data-testid="FILTER_BANNER_FIXED"
              zIndex="5"
              position="fixed"
              top="0"
              right="0"
              left="0"
              ref={sizeRef}
            >
              <FilterBar
                destination={destination}
                destinations={destinations}
                setDestination={setDestination}
              />
            </Box>
          </Portal>
        </>
      ) : (
        <div data-testid="FILTER_BANNER" ref={sizeRef}>
          <FilterBar
            destination={destination}
            destinations={destinations}
            setDestination={setDestination}
          />
        </div>
      )}
    </Box>
  );
};

export default FilterBanner;
