import React from 'react';
import PropTypes from 'prop-types';
import SquareHeroBanner from '@/components/SquareHeroBanner';
import Campaign from '@/propTypes/Campaign';

const HeroBanner = React.memo(({ campaign }) => {
  const { campaignLandingPage: campaignPage, image } = campaign;
  const headerAltText = 'Luxury Holidays and Escapes';

  return (
    <SquareHeroBanner
      background={{
        image,
        width: 1600,
        alt: headerAltText,
      }}
      content={{
        title: campaignPage.headline,
        text: campaignPage.tagline,
      }}
    />
  );
});

HeroBanner.propTypes = {
  campaign: PropTypes.shape(Campaign).isRequired,
};

export default HeroBanner;
