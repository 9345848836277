import PropTypes from 'prop-types';
import React from 'react';
import { Button, Heading, Paragraph, Icon } from '@qga/roo-ui/components';
import { isSanityImage } from '@experiences-ui/shared/utils';
import Image from 'next/image';
import BackLink from '@/components/BackLink';
import { SanityImage } from '@experiences-ui/shared/components';
import SquareHeroBannerLayout from '../SquareHeroBannerLayout';

const SquareHeroBanner = ({ background, content, hasBackLink, ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <SquareHeroBannerLayout.Root {...props}>
    <SquareHeroBannerLayout.Background>
      {isSanityImage(background.image) ? (
        <SanityImage
          data-testid="SANITY_BACKGROUND_IMAGE"
          image={background.image}
          alt={background.alt}
          objectFit="cover"
        />
      ) : (
        <Image
          data-testid="NEXT_BACKGROUND_IMAGE"
          src={background.image}
          alt={background.alt}
          layout="fill"
          objectFit="cover"
        />
      )}
    </SquareHeroBannerLayout.Background>
    <SquareHeroBannerLayout.Content>
      {hasBackLink && <BackLink>Back</BackLink>}
      {content.sash}
      <Heading.h1 pt={2} fontSize={['xl', null, '3xl']} fontWeight="normal">
        {content.title}
      </Heading.h1>
      <Paragraph mb={content.button ? 4 : 0} color="greys.steel">
        {content.text}
      </Paragraph>
      {content.button && (
        <Button variant="primary" onClick={content.button.onClick}>
          {content.button.label} <Icon name="arrowDownward" />
        </Button>
      )}
    </SquareHeroBannerLayout.Content>
  </SquareHeroBannerLayout.Root>
);

SquareHeroBanner.defaultProps = {
  hasBackLink: false,
};

SquareHeroBanner.propTypes = {
  background: PropTypes.shape({
    alt: PropTypes.string.isRequired,
    image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  }).isRequired,
  content: PropTypes.shape({
    button: PropTypes.shape({
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func,
    }),
    text: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    sash: PropTypes.node,
  }).isRequired,
  hasBackLink: PropTypes.bool,
};

export default SquareHeroBanner;
