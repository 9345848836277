import { useCallback, useState, useRef } from 'react';

const useSticky = () => {
  const [sticky, setSticky] = useState(false);
  const onScrollRef = useRef();

  const ref = useCallback((element) => {
    if (!element) {
      const onScroll = onScrollRef.current;
      window.removeEventListener('scroll', onScroll);

      return;
    }

    const onScroll = () => setSticky(window.scrollY > element.offsetTop);

    onScroll();
    window.addEventListener('scroll', onScroll);

    onScrollRef.current = onScroll;
  }, []);

  return {
    sticky,
    ref,
  };
};

export default useSticky;
