import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import Select from '@experiences-ui/shared/v2/components/Select';
import { ALL_DESTINATIONS } from '@/constants';
import { useGA4Events } from '@/v2/hooks/featureFlags/ga4Events/useGA4Events';
import { travellingToChangeEvent } from '@/v2/utils/dataLayer/searchEvents/events';
import scrollTo from '@/libs/v2/utils/scrollIntoView';

const TravellingTo = ({ value, destinations, onSubmit }) => {
  const shouldUseNewEvents = useGA4Events();

  const options = useMemo(
    () => [
      { text: 'All locations', value: ALL_DESTINATIONS },
      ...destinations.map((o) => ({ text: o.title, value: o.name })),
    ],
    [destinations],
  );

  const handleChange = (newValue) => {
    if (newValue === ALL_DESTINATIONS) {
      onSubmit(null);
    } else {
      if (shouldUseNewEvents && newValue && value !== newValue) {
        const destinationName = options.find(
          (option) => option.value === newValue,
        )?.text;

        if (destinationName) {
          travellingToChangeEvent(destinationName);
        }
      }

      onSubmit(destinations.find((d) => d.name === newValue));
    }

    const PROPERTIES_CONTAINER_OFFSET = -40;
    scrollTo('#featured-properties', { offset: PROPERTIES_CONTAINER_OFFSET });
  };

  return (
    <Select
      id="TRAVELLING_TO"
      value={value || ''}
      options={options}
      disabled={options.length === 0}
      onSelectedChange={handleChange}
      placeholder="Travelling to"
      prefix="To"
    />
  );
};

TravellingTo.defaultProps = {
  value: undefined,
};

TravellingTo.propTypes = {
  value: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  destinations: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default TravellingTo;
