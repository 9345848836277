import filterCampaignPropertiesByOrigin from '@/components/CampaignPage/utils/filterCampaignPropertiesByOrigin';
import getLeadInPriceFromProcuredOffer from '@/utils/getLeadInPriceFromProcuredOffer';
import { UI } from '@/v2/types';
import getEcommerceItem from '@/v2/utils/dataLayer/eventDataHelpers/getEcommerceItemEventData';
import bundleBuilder from '@/components/ExclusiveOffersPage/components/ArticleFooter/utils/bundleBuilder';
import { FLIGHTS_HOTEL_INCLUSIONS_TITLE } from '@/components/ExclusiveOffersPage/components/ArticleFooter/utils/getBundle/getBundle';

type ExclusiveOffersEcommerceItems = {
  originCode: string;
  tiles: UI.Tile[];
  tiers: UI.QFFPointTiersInstance;
};
const getExclusiveOffersEcommerceItems = ({
  originCode,
  tiles,
  tiers,
}: ExclusiveOffersEcommerceItems) => {
  const filteredProperties: UI.Tile[] = tiles;

  /*
   * This is a list of properties where this is a "complete" bundle
   * (eg. the Hotels or Holidays offer exists and is valid)
   */
  const availableProperties: Array<UI.Tile & { isHolidaysOffer: boolean }> = [];
  filteredProperties.forEach((property) => {
    const { bundles } = bundleBuilder(property, {
      originCode,
    });

    // Enrich the property with an isHolidaysOffer value, so we know which price to use
    bundles.forEach(
      (bundle: { title?: string; isCompleteBundle?: boolean }) => {
        if (bundle.isCompleteBundle) {
          availableProperties.push({
            ...property,
            isHolidaysOffer: bundle?.title === FLIGHTS_HOTEL_INCLUSIONS_TITLE,
          });
        }
      },
    );
  });

  return availableProperties.map((property, idx: number) => {
    const { procuredOffer, propertyContent, isHolidaysOffer } = property;
    const leadInOffer = procuredOffer?.rooms[0]?.leadInOffers[0];

    const destinationCode =
      procuredOffer?.destinationCode ||
      propertyContent?.destination?.searchConfig?.destinationCode;

    const price = isHolidaysOffer
      ? procuredOffer &&
        getLeadInPriceFromProcuredOffer(procuredOffer, {
          originCode,
          destinationCode,
        })?.total?.amount
      : leadInOffer?.staysOnlyOffer?.offerTotal?.amount;

    return getEcommerceItem({
      originCode,
      property,
      tiers,
      price: price && typeof price === 'string' ? Number(price) : price,
      index: idx + 1,
    });
  });
};

export default getExclusiveOffersEcommerceItems;
