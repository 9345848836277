import PropTypes from 'prop-types';
import React, { useMemo, useEffect } from 'react';
import { Box, Container, Heading } from '@qga/roo-ui/components';
import Campaign, { Tile } from '@/propTypes/Campaign';
import Destination from '@/propTypes/Destination';

import DataLayer from './components/DataLayer';
import Meta from './components/Meta';
import CardListing from './components/CardListing';
import HeroBanner from './components/HeroBanner';
import FAQs from '@/components/FAQs';
import CVPBanner from '@/v2/components/ui/CVPBanner';
import ConditionsMarkdown from '@/components/ConditionsMarkdown';
import Destinations from '@/components/Destinations';
import WebChat from '@/components/WebChat';
import useBreakpoints, { isScreen } from '@/hooks/useBreakpoints';
import FilterBanner from './components/FilterBanner';
import getPrimaryOrigin from '@/utils/getPrimaryOrigin';
import useTiers from '@/hooks/useTiers';
import { ORIGINS } from '@/constants';
import getExclusiveOffersEcommerceItems from '@/v2/utils/dataLayer/eventDataHelpers/getExclusiveOffersEcommerceItems';
import useViewItemListEvent from '@/v2/hooks/useViewItemListEvent';
import useTravellingTo from './hooks/useTravellingTo';

const WrapWithContainer = ({ children }) => {
  const breakpoints = useBreakpoints();
  return isScreen(breakpoints)('xs', 'sm') ? (
    children
  ) : (
    <Container>{children}</Container>
  );
};

const ExclusiveOffersPage = ({ campaign, destinations, tiles }) => {
  const { campaignLandingPage: campaignPage } = campaign;
  const tiers = useTiers();

  const {
    destination,
    setDestination,
    destinations: travellingToDestinations,
    filteredProperties,
  } = useTravellingTo(tiles);

  const exclusiveOffersEcommerceItems = useMemo(() => {
    return getExclusiveOffersEcommerceItems({
      originCode: getPrimaryOrigin(ORIGINS)?.code,
      tiles,
      tiers,
    });
  }, [tiers, tiles]);

  const { handleInvokeViewItemListEvent } = useViewItemListEvent({
    items: exclusiveOffersEcommerceItems,
    title: campaignPage.headline,
    shouldAutoInvokeOnce: true,
  });

  // GA event for when user changes origin
  useEffect(() => {
    handleInvokeViewItemListEvent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [destination]);

  return (
    <>
      <DataLayer campaign={campaign} />
      <Meta title={campaignPage.headline} description={campaignPage.tagline} />
      <HeroBanner campaign={campaign} />
      <Box py="4">
        <Container px="0">
          <CVPBanner valuePropositions={campaignPage.valuePropositions} />
        </Container>
      </Box>
      <FilterBanner
        destination={destination}
        destinations={travellingToDestinations}
        setDestination={setDestination}
      />
      <Box bg="greys.porcelain" id="featured-properties" pt={12}>
        <Container>
          <Box mx={[null, null, -4]} pt="6">
            {campaignPage.tileHeadline && campaignPage.tileHeadline.length && (
              <Heading.h3
                fontWeight="normal"
                mb={['4', null, '9']}
                textAlign="center"
              >
                {campaignPage.tileHeadline}
              </Heading.h3>
            )}
          </Box>
        </Container>
        <WrapWithContainer>
          <CardListing tiles={filteredProperties} />
        </WrapWithContainer>
        <Container>
          {campaignPage.tileTermsConditions && (
            <Box py="10" fontSize="sm">
              <ConditionsMarkdown
                subtle
                markdown={campaignPage.tileTermsConditions}
              />
            </Box>
          )}
        </Container>
      </Box>
      {campaign.faqs && (
        <Box bg="greys.porcelain" pb="16">
          <FAQs faqs={campaign.faqs} />
        </Box>
      )}
      <Box py="12">
        <Container>
          <Destinations
            heading={campaignPage.destinationHeadline}
            destinations={destinations}
          />
        </Container>
      </Box>
      <WebChat />
    </>
  );
};

ExclusiveOffersPage.propTypes = {
  campaign: PropTypes.shape(Campaign).isRequired,
  destinations: PropTypes.arrayOf(Destination).isRequired,
  tiles: PropTypes.arrayOf(Tile).isRequired,
};

export default ExclusiveOffersPage;
