import { useEffect, useState } from 'react';
import { uniqBy } from 'ramda';
import type { UI } from '@/v2/types';

interface Destination {
  destinationCode: string;
  title: string;
  name: string;
  origins: {
    code: string;
    name: string;
  }[];
}

const filterCampaignPropertiesByDestination = (
  campaignProperties: UI.Campaign['tiles'],
  destination: Destination | null,
) => {
  if (!destination) {
    return campaignProperties;
  }

  return campaignProperties.filter(
    (p) => p.propertyContent.destination.name === destination.name,
  );
};

const useTravellingTo = (campaignProperties: UI.Campaign['tiles']) => {
  const [destination, setDestination] = useState<Destination | null>(null);
  const [filteredProperties, setFilteredProperties] =
    useState(campaignProperties);

  const destinations = uniqBy(
    ({ name }) => name,
    campaignProperties.map((p) => p.propertyContent?.destination),
  );

  useEffect(() => {
    setFilteredProperties(
      filterCampaignPropertiesByDestination(campaignProperties, destination),
    );
  }, [campaignProperties, destination]);

  return {
    destination,
    setDestination,
    destinations,
    filteredProperties,
  };
};

export default useTravellingTo;
