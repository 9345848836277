import type { UI } from '@/v2/types';

import React, { useEffect } from 'react';
import { Box, Container } from '@qga/roo-ui/components';
import { Layout } from '@/v2/components/layout/Layout';
import { Page } from '@/v2/components/layout/Page';
import DestinationLinks from '@/v2/components/ui/SiteFooter/components/DestinationLinks';
import ImportantInformation from '@/components/ImportantInformation';
import usePageViewEvent from '@/v2/hooks/usePageViewEvent';

import { ExclusiveOffersPage } from '../../../../components/ExclusiveOffersPage';

import { dataLayer } from './utils/dataLayer';

interface Props {
  app: UI.Settings;
  navigation: Array<UI.Navigation>;
  campaign: UI.Campaign;
  destinations: Array<UI.Destination>;
  tiles: UI.Campaign['tiles'];
}

const CampaignView = ({ campaign, destinations, tiles, ...rest }: Props) => {
  useEffect(() => {
    dataLayer();
  }, []);

  usePageViewEvent({ type: 'deals' });

  return (
    <Layout {...rest}>
      <Page data-testid="CAMPAIGN_PAGE">
        <ExclusiveOffersPage
          //@ts-ignore
          campaign={campaign}
          destinations={destinations}
          tiles={tiles}
        />
      </Page>
      <Box pt="9" pb="15" bg="white">
        <Container>
          <ImportantInformation />
        </Container>
      </Box>
      <DestinationLinks />
    </Layout>
  );
};

export default CampaignView;
