import PropTypes from 'prop-types';
import { useMemo } from 'react';
import useDataLayer from '@/hooks/useDataLayer';
import Campaign from '@/propTypes/Campaign';

const DataLayer = ({ campaign }) => {
  const event = useMemo(
    () => ({
      event: 'pageView',
      pageType: `Exclusive offers page: ${campaign.slug}`,
    }),
    [campaign.slug],
  );

  useDataLayer(event);

  return null;
};

DataLayer.propTypes = {
  campaign: PropTypes.shape(Campaign).isRequired,
};

export default DataLayer;
