import PropTypes from 'prop-types';
import React from 'react';
import Router from 'next/router';
import { NakedButton } from '@qga/roo-ui/components';
import Breadcrumb from '@/components/Breadcrumb';
import Subtext from '@/components/Subtext';
import isInternalUrl from '@/utils/isInternalUrl';
import { useCustomerSelection } from '@/components/CustomerSelectionProvider';

const BackLink = ({ children, fallbackUrl }) => {
  const { previousRoute } = useCustomerSelection();

  const onClick = () =>
    isInternalUrl(previousRoute) ? Router.back() : Router.push(fallbackUrl);

  return (
    <Breadcrumb>
      <NakedButton textDecoration="underline" onClick={onClick}>
        <Subtext>{children}</Subtext>
      </NakedButton>
    </Breadcrumb>
  );
};

BackLink.defaultProps = {
  fallbackUrl: '/',
};

BackLink.propTypes = {
  children: PropTypes.node.isRequired,
  fallbackUrl: PropTypes.string,
};

export default BackLink;
