import React from 'react';
import Head from 'next/head';
import DocumentTitle from '@/components/DocumentTitle';
import MetaDescription from '@/components/MetaDescription';
import { BASE_URL, BASE_PATH } from '@/config/public';

const Meta = ({ title, description }) => (
  <>
    <DocumentTitle>{title}</DocumentTitle>
    <MetaDescription content={description} />
    <Head>
      <link rel="canonical" href={`${BASE_URL}${BASE_PATH}/exclusive-offers`} />
    </Head>
  </>
);

export default Meta;
