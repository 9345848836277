import { Text } from '@qga/roo-ui/components';
import withProps from '../withProps';

const Subtext = withProps(({ ...props }) => ({
  color: 'greys.steel',
  fontSize: 'sm',
  ...props,
}))(Text);

export default Subtext;
