import React from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';

const MetaDescription = ({ content }) => (
  <Head>
    <meta name="description" content={content} />
  </Head>
);

MetaDescription.propTypes = {
  content: PropTypes.string.isRequired,
};

export default MetaDescription;
