import React from 'react';
import PropTypes from 'prop-types';
import { Box, Container, Disclosure, Heading } from '@qga/roo-ui/components';
import BlockContent from '@sanity/block-content-to-react';
import withProps from '@/components/withProps';
import Link from '@/components/Link';

const FAQs = ({ faqs, headingStyles }) => (
  <Box bg="white" py={['5', null, '10']}>
    <Container>
      <Heading.h2
        fontSize="xl"
        fontWeight="normal"
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...headingStyles}
      >
        Frequently Asked Questions
      </Heading.h2>
      {faqs.map(({ question, answer }) => (
        <Disclosure key={question} headerTitle={question} expand={false}>
          <BlockContent
            blocks={answer}
            serializers={{
              marks: {
                link: withProps(({ mark }) => ({
                  href: mark.href,
                  external: true,
                }))(Link.Text),
              },
            }}
          />
        </Disclosure>
      ))}
    </Container>
  </Box>
);

FAQs.defaultProps = {
  headingStyles: undefined,
};

FAQs.propTypes = {
  faqs: PropTypes.arrayOf(
    PropTypes.shape({
      question: PropTypes.string,
      answer: PropTypes.any,
    }),
  ).isRequired,
  headingStyles: PropTypes.object,
};

export default FAQs;
