import { useCallback, useMemo, useState } from 'react';
import useTiers from '@/hooks/useTiers';
import useSearchQuery from '@/libs/v2/hooks/useSearchQuery';
import { useCustomerSelection } from '@/components/CustomerSelectionProvider';
import getEcommerceItem, {
  Property,
} from '@/v2/utils/dataLayer/eventDataHelpers/getEcommerceItemEventData';
import getPrimaryOrigin from '@/utils/getPrimaryOrigin';
import { ORIGINS } from '@/constants';
import { useGA4Events } from '@/v2/hooks/featureFlags/ga4Events/useGA4Events';
import { useIsFeatureFlagLoaded } from '@/libs/v2/contexts/featureFlags';
import dataLayer from '@/v2/utils/dataLayer/dataLayer';
import { SanityApi } from '@/v2/services/sanity/types';
import { EcommerceItem } from '@/libs/v2/utils/dataLayer';
import useFlyingFrom from '@/libs/v2/hooks/useFlyingFrom';
import { getNightsStay } from '@/v2/utils/dataLayer/eventDataHelpers/getEcommerceDetailedItemEventData';

interface UseViewItemListEventProps {
  origins?: SanityApi.ParsedResponse.OriginCodes[];
  originCode?: string;
  properties: Property[];
  destinationTitle?: string;
  searchType: 'list' | 'map';
  title: string;
  items?: EcommerceItem[];
  shouldAutoInvokeOnce?: boolean;
  isPostSearch?: boolean;
}

const useViewItemListEvent = ({
  origins,
  originCode: code,
  properties,
  destinationTitle,
  searchType = 'list',
  title,
  items: ecommerceItems,
  shouldAutoInvokeOnce,
  isPostSearch,
}: UseViewItemListEventProps) => {
  const [hasBeenInvoked, setHasBeenInvoked] = useState(false);
  const shouldUseNewEvents = useGA4Events();
  const areFeatureFlagsReady = useIsFeatureFlagLoaded();
  const { usePoints } = useCustomerSelection();
  const tiers = useTiers();
  const [key, setKey] = useState('');

  const originCodes = useMemo(
    () => (origins || ORIGINS).map((o) => o.code),
    [origins],
  );

  const { searchQuery } = useSearchQuery();

  const { originCode: FFOriginCode, isReady: FFIsReady } =
    useFlyingFrom(originCodes);

  const isReady = useMemo(
    () => shouldUseNewEvents && areFeatureFlagsReady && FFIsReady,
    [areFeatureFlagsReady, FFIsReady, shouldUseNewEvents],
  );

  const handleInvokeViewItemListEvent = useCallback(() => {
    if (!isReady) return;

    const originCode = code
      ? code
      : FFOriginCode ?? getPrimaryOrigin(origins || ORIGINS)?.code;

    const duration = getNightsStay(
      searchQuery?.departureDate,
      searchQuery?.returnDate,
    );

    const items =
      ecommerceItems ||
      properties.map((property: Property, idx: number) => {
        let price, itemVariant;
        if (isPostSearch) {
          price = property.packageOffer?.charges?.total?.amount;
          itemVariant = property.packageOffer?.roomTypeName;
        }

        return getEcommerceItem({
          property,
          tiers,
          originCode,
          index: idx + 1,
          price: price && typeof price === 'string' ? Number(price) : undefined,
          itemVariant,
        });
      });

    const payload = {
      items,
      usePoints,
      // Destination pages will be "Melbourne", "Sydney" etc., where as themes, campaign pages etc.
      // will be "City breaks", "Romantic getaways"
      destination: destinationTitle || title,
      searchType,
      origin:
        originCode &&
        (origins || ORIGINS).find(
          (o: { code: string }) => o.code === originCode,
        )?.name,
      name: title,
      // Post-search data
      startDate: searchQuery?.departureDate,
      endDate: searchQuery?.returnDate,
      duration,
      travellers: {
        adults: searchQuery?.adults,
        children: searchQuery?.children,
        infants: searchQuery?.infants,
      },
    };

    // The key prevents us from duplicate events due to re-renders.
    const newKey = JSON.stringify({
      items: payload.items,
      destination: payload.destination,
    });

    if (key === newKey) {
      return;
    }

    setKey(newKey);

    return dataLayer.viewItemListEvent(payload);
  }, [
    isReady,
    code,
    FFOriginCode,
    origins,
    searchQuery?.departureDate,
    searchQuery?.returnDate,
    searchQuery?.adults,
    searchQuery?.children,
    searchQuery?.infants,
    ecommerceItems,
    properties,
    usePoints,
    destinationTitle,
    title,
    searchType,
    key,
    isPostSearch,
    tiers,
  ]);

  if (shouldAutoInvokeOnce && !hasBeenInvoked && isReady) {
    handleInvokeViewItemListEvent();
    setHasBeenInvoked(true);
  }

  return {
    isReady,
    handleInvokeViewItemListEvent,
  };
};

export default useViewItemListEvent;
