import PropTypes from 'prop-types';
import React from 'react';
import { Box, Flex, Icon } from '@qga/roo-ui/components';

const Breadcrumb = ({ children }) => (
  <Flex alignItems="center">
    <Icon name="chevronLeft" size={18} />
    <Box fontSize="sm">{children}</Box>
  </Flex>
);

Breadcrumb.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Breadcrumb;
