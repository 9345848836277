// export { default } from '@/components/ExclusiveOffersPage';

import type { GetStaticPropsContext, InferGetStaticPropsType } from 'next';
import { ISR_REVALIDATE_IN_SECONDS } from '@/v2/constants';
import { EXCLUSIVE_OFFERS_PAGE_CAMPAIGN_SLUG } from '@experiences-ui/shared/constants';
import { getCampaignPage } from '@/v2/services/bff/getCampaignPage';
import { CampaignView } from '@/v2/components/pages/CampaignView';
import { Meta } from '@/v2/components/global/Meta';
import { DataLayerProvider } from '@/libs/v2/contexts/DataLayerContext';
import { logger } from '@/libs/utils';

export const getStaticProps = async ({ preview }: GetStaticPropsContext) => {
  try {
    const props = await getCampaignPage({
      preview,
      slug: EXCLUSIVE_OFFERS_PAGE_CAMPAIGN_SLUG,
    });

    return {
      props,
      revalidate: ISR_REVALIDATE_IN_SECONDS,
    };
  } catch (error) {
    logger.error(error, 'DEALS_ERROR');

    return {
      redirect: {
        destination: '/',
        permanent: true,
      },
    };
  }
};

const dataLayerData = { pageName: 'Exclusive Offer' };

const CampaignPage = ({
  app,
  meta,
  campaign,
  destinations,
  tiles,
  navigation,
}: InferGetStaticPropsType<typeof getStaticProps>) => {
  return (
    <DataLayerProvider value={dataLayerData}>
      <Meta {...meta} />
      <CampaignView
        app={app}
        navigation={navigation}
        campaign={campaign}
        destinations={destinations}
        tiles={tiles}
      />
    </DataLayerProvider>
  );
};

export default CampaignPage;
