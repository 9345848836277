import dataLayer from '../../../../../../../../../libs/shared/utils/dataLayer';

const pageViewEvent = () => {
  return dataLayer.push({
    event: 'pageView',
    pageType: 'Deals Page',
  });
};

export default pageViewEvent;
